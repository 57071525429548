import React from 'react';
import translate from '@/utils/translate';
import styles from './CreatorVettingReport.module.scss';
import ProfileVettingSummary from './components/profile-vetting-summary';

const TRANSLATION_PREFIX = 'components.creator-vetting-report';

export interface CreatorVettingReportProps {
  testID?: string;
  vettingReport: any;
  showProfileInfo?: boolean;
}

function CreatorVettingReport(props: CreatorVettingReportProps) {
  const {
    testID = 'creator-vetting-report',
    vettingReport,
    showProfileInfo = true,
  } = props;

  const [liveTrackingProxy, setLiveTrackingProxy] = React.useState(
    vettingReport.liveTracking
  );

  const handleOnTrackingChanged = (isTracking: boolean) => {
    setLiveTrackingProxy(isTracking);
  };

  return (
    <div className={styles.creatorVettingReportContainer} data-testid={testID}>
      <div className={styles.sideBarContainer}>
        <div className={styles.profileVettingSummaryContainer}>
          <ProfileVettingSummary
            profileId={vettingReport.profileId}
            profileImageUrl={vettingReport.thumbnailUrl}
            profileDisplayName={vettingReport.profileName}
            showProfileInfo={showProfileInfo}
            isTracking={liveTrackingProxy}
            onTrackingChanged={handleOnTrackingChanged}
            summary={vettingReport.summary}
            lastScanDate={vettingReport.lastScanDate}
          />
        </div>
        <div className={styles.scannedPlatformsContainer}>ScannedPlatforms</div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.vettingSummaryContainer}>
          <div className={styles.itemsScannedAndClear}>
            <div className={styles.itemsScannedContainer}>ItemsScanned</div>
            <div className={styles.itemsClearContainer}>ItemsClear</div>
          </div>
          <div className={styles.itemsFlaggedContainer}>ItemsFlagged</div>
        </div>
        <div className={styles.flaggedContentContainer}>
          <div className={styles.flaggedContentTitleContainer}>
            <div>Flagged Content + Filters</div>
          </div>
          <div className={styles.flaggedContentItemsContainer}>
            FlaggedContentItems
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatorVettingReport;
