import dayjs from 'dayjs';
import QueryContext from '../QueryContext';
import api from '../index';

const MOCK_DATA = {
  data: {
    id: '1',
    profileName: 'Amelia Weiss',
    profileId: 'de292675-8c09-40d2-b117-9080e03fa9be',
    liveTracking: true,
    lastScanDate: '05-05-2024 12:00:00',
    summary:
      "Amelia Weiss's posts contain a few instances of adult content, references to alcohol, and some instances of content that could be considered indecent. Amelia Weiss is considered low risk for PR due to the lack of problematic content in her posts and search results.",
    thumbnailUrl:
      'https://s3.amazonaws.com/turbo.popularpays.com/profiles/images/de292675-8c09-40d2-b117-9080e03fa9be/thumb_jpg/8a5b827f97c1030239c05245f7af66b8.jpg?1598560969',
    analysisStats: {
      platforms: [
        {
          id: '1',
          name: 'Instagram',
          startDate: '01-01-2021 12:00:00',
          endDate: '01-01-2024 12:00:00',
          mediaTypes: ['Feed posts', 'Reels', 'Stories', 'Live'],
        },
        {
          id: '2',
          name: 'TikTok',
          startDate: '01-01-2021 12:00:00',
          endDate: '01-01-2024 12:00:00',
          mediaTypes: ['Videos', 'Live'],
        },
        {
          id: '3',
          name: 'YouTube',
          startDate: '01-01-2021 12:00:00',
          endDate: '01-01-2024 12:00:00',
          mediaTypes: [
            'Long form videos',
            'Shorts',
            'Live streams',
            'Community posts',
          ],
        },
        {
          id: '4',
          name: 'Google search',
          startDate: '01-01-2021 12:00:00',
          endDate: dayjs().format('DD-MM-YYYY HH:mm:ss'),
        },
      ],
    },
    backgroundChecks: [
      {
        source: 'google',
        url: 'https://www.nytimes.com/2022/01/01/cautionary-tale-internet-fame.html',
        site: 'New York Times blog post',
        title: 'A cautionary tale of internet fame',
        summary:
          'The article describes content labeled as mildly offensive. This description downplays the potential impact, lacks specificity, and fails to acknowledge the creator’s responsibility to handle sensitive topics carefully.',
        confidenceLevel: 'MEDIUM',
        date: '17-06-2024 12:00:00',
        siteIconUrl: 'https://www.nytimes.com/favicon.ico',
        images: [
          'https://picsum.photos/200/300',
          'https://picsum.photos/200/300',
        ],
      },
      {
        source: 'google',
        url: 'https://www.reddit.com/r/AmeliaWeiss',
        site: 'Reddit sub-thread',
        title: 'Whats up with Amelia’s lawsuit?',
        summary:
          'Heated discussion about whether or not Amelia had a lawsuit which was squished due to a pay-off.',
        confidenceLevel: 'MEDIUM',
        date: '17-06-2024 12:00:00',
        siteIconUrl: 'https://www.reddit.com/favicon.ico',
        images: [
          'https://picsum.photos/200/300',
          'https://picsum.photos/200/300',
          'https://picsum.photos/200/300',
          'https://picsum.photos/200/300',
          'https://picsum.photos/200/300',
        ],
      },
    ],
    flaggedPosts: [
      {
        id: 'posts-3f3ca81c-36b5-425b-9eae-b275345c77e9',
        type: 'instagram_business_story',
        date: '2024-07-17T00:02:51.000Z',
        mediaUrl:
          'https://social-data-production.s3.amazonaws.com/0ja7sfmezu5ld68xenqwp9c8axkl',
        media_type: 'image',
        caption: '@cherriesnash',
        analysis: {
          adult: false,
          drugs: false,
          racial: false,
          vaping: false,
          alcohol: true,
          rRated: false,
          smoking: false,
          summary:
            "The image depicts a martini glass filled with a clear liquid, which is an alcoholic beverage. Therefore, the 'alcohol' category is marked as true.",
          tobacco: false,
          weapons: false,
          bullying: false,
          gambling: false,
          violence: false,
          obscenity: false,
          offensive: false,
          profanity: false,
          selfHarm: false,
          terrorism: false,
          indelicate: false,
          hateSpeech: false,
          foulLanguage: false,
          animalCruelty: false,
          discrimination: false,
          sexualContent: false,
          graphicContent: false,
          politicalContent: false,
          religiousContent: false,
          childExploitation: false,
          illegalActivities: false,
          sensitiveSocialIssues: false,
        },
      },
      {
        id: 'posts-b31db8f1-8e9f-45b9-96a2-65f1a9e22c96',
        type: 'instagram_business_feed',
        date: '2024-07-05T18:09:41.000Z',
        mediaUrl:
          'https://social-data-production.s3.amazonaws.com/4fi0n5ypnitpzyclwrhvg0h2jd7d',
        media_type: 'image',
        caption: 'wet, hot, American nights',
        analysis: {
          adult: true,
          drugs: false,
          racial: false,
          vaping: false,
          alcohol: false,
          rRated: true,
          smoking: false,
          summary:
            "The image contains adult content due to the person's attire. The person is wearing a bikini and is standing in the back of the vehicle. The caption does not provide any additional context.",
          tobacco: false,
          weapons: false,
          bullying: false,
          gambling: false,
          violence: false,
          obscenity: false,
          offensive: false,
          profanity: false,
          selfHarm: false,
          terrorism: false,
          indelicate: false,
          hateSpeech: false,
          foulLanguage: false,
          animalCruelty: false,
          discrimination: false,
          sexualContent: false,
          graphicContent: false,
          politicalContent: false,
          religiousContent: false,
          childExploitation: false,
          illegalActivities: false,
          sensitiveSocialIssues: false,
        },
      },
      {
        id: 'posts-10c431ee-ba43-4f00-8fc7-f0148c690b69',
        type: 'instagram_business_feed',
        date: '2024-06-25T20:43:10.000Z',
        mediaUrl:
          'https://social-data-production.s3.amazonaws.com/scldgboyqn1ti5ywdsg2a45p6qst',
        media_type: 'image',
        caption: 'teeny bikini summer commences',
        analysis: {
          adult: true,
          drugs: false,
          racial: false,
          vaping: false,
          alcohol: false,
          rRated: true,
          smoking: false,
          summary:
            'The image contains indelicate and adult content due to the revealing nature of the bikini worn by the woman. The image is not sexually explicit but shows a significant amount of skin.',
          tobacco: false,
          weapons: false,
          bullying: false,
          gambling: false,
          violence: false,
          obscenity: false,
          offensive: false,
          profanity: false,
          selfHarm: false,
          terrorism: false,
          indelicate: true,
          hateSpeech: false,
          foulLanguage: false,
          animalCruelty: false,
          discrimination: false,
          sexualContent: false,
          graphicContent: false,
          politicalContent: false,
          religiousContent: false,
          childExploitation: false,
          illegalActivities: false,
          sensitiveSocialIssues: false,
        },
      },
      {
        id: 'posts-b40c687c-8c85-4492-8939-8dbfc1496508',
        type: 'instagram_business_story',
        date: '2024-07-17T00:08:26.000Z',
        mediaUrl:
          'https://social-data-production.s3.amazonaws.com/urh1b6nu1sbbm5minz3bse06y95p',
        media_type: 'image',
        caption: null,
        analysis: {
          adult: true,
          drugs: false,
          racial: false,
          vaping: false,
          alcohol: false,
          rRated: true,
          smoking: false,
          summary:
            "The image contains offensive and indelicate content due to the woman's nudity. The image also depicts adult content.",
          tobacco: false,
          weapons: false,
          bullying: false,
          gambling: false,
          violence: false,
          obscenity: false,
          offensive: true,
          profanity: false,
          selfHarm: false,
          terrorism: false,
          indelicate: true,
          hateSpeech: false,
          foulLanguage: false,
          animalCruelty: false,
          discrimination: false,
          sexualContent: false,
          graphicContent: false,
          politicalContent: false,
          religiousContent: false,
          childExploitation: false,
          illegalActivities: false,
          sensitiveSocialIssues: false,
        },
      },
    ],
  },
};

const vettingFetchers = {
  report: (context: QueryContext) => MOCK_DATA,
  // api.get(`/vetting_report/${context.meta?.vettingReportId}`, {
  //   params: {
  //     brand_id: context.meta?.brandId,
  //   },
  // }),
};

export default vettingFetchers;
