import React from 'react';
import {
  Body,
  designSystemToken,
  Headline,
  Label,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {ROUTES} from '@/config/routesConstants';
import Conditional from '@/components/conditional';
import CreatorProfileAvatar from '@/components/creator-profile-avatar';
import Link from '@/components/link';
import TrackingButton from '../tracking-button';
import styles from './ProfileVettingSummary.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-summary';

interface ProfileVettingSummaryProps {
  profileId: string;
  profileImageUrl: string;
  profileDisplayName: string;
  showProfileInfo?: boolean;
  isTracking?: boolean;
  onTrackingChanged: (isTracking: boolean) => void;
  summary: string;
  lastScanDate?: string;
}

function ProfileVettingSummary(props: ProfileVettingSummaryProps) {
  const {
    profileId,
    profileImageUrl,
    profileDisplayName,
    showProfileInfo = true,
    isTracking,
    onTrackingChanged,
    summary,
    lastScanDate,
  } = props;

  return (
    <div className={styles.profileVettingSummaryContainer}>
      <div className={styles.profileInfoAndTracking}>
        <Conditional condition={showProfileInfo}>
          <div className={styles.profileInfo}>
            <CreatorProfileAvatar
              profileImageUrl={profileImageUrl}
              profileDisplayName={profileDisplayName}
              size="3xl"
              allowEnlarge={false}
            />
            <Headline size="xl">{profileDisplayName}</Headline>
          </div>
        </Conditional>
        <div className={styles.trackingContainer}>
          <TrackingButton
            isTracking={isTracking}
            onTrackingChanged={onTrackingChanged}
            lastScanDate={lastScanDate}
          />
        </div>
      </div>
      <div className={styles.summary}>
        <Body size="md" color={designSystemToken('semantic.fg.secondary')}>
          {summary}
        </Body>
      </div>
      <Conditional condition={showProfileInfo}>
        <Link to={`${ROUTES.CREATOR_PROFILE}/${profileId}`} target="_blank">
          <Label
            size="md"
            color={designSystemToken('semantic.fg.brand-secondary')}
          >
            {translate(`${TRANSLATION_PREFIX}.go-to-full-profile`)}
          </Label>
        </Link>
      </Conditional>
    </div>
  );
}

export default ProfileVettingSummary;
