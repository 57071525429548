import React from 'react';
import Page from '@/components/page';
import styles from './VettingHub.module.scss';

function VettingHub() {
  return (
    <Page id="vetting-hub" className={styles.vettingHubContainer}>
      <h1>Vetting Hub View</h1>
    </Page>
  );
}

export default VettingHub;
