import vettingFetchers from '@/api/fetchers/vetting';
import useDataQuery from '@/hooks/use-data-query';

function useVettingReportQuery({
  vettingReportId,
  brandId,
}: {
  vettingReportId: string;
  brandId: string;
}) {
  return useDataQuery({
    queryKey: ['vettingReport', vettingReportId],
    queryFn: vettingFetchers.report,
    enabled: !!brandId && !!vettingReportId,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    meta: {
      vettingReportId,
      brandId,
    },
  });
}

export default useVettingReportQuery;
