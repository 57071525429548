import React from 'react';
import CreatorVettingReport from '@/components/creator-vetting-report';
import Page from '@/components/page';
import useVettingReportQuery from '@/hooks/queries/use-vetting-report-query';
import useBrandId from '@/hooks/use-brand-id';
import useParams from '@/hooks/use-params';
import styles from './VettingReport.module.scss';

function VettingReport() {
  const {vettingReportId} = useParams();
  const brandId = useBrandId();
  const {data: vettingReport} = useVettingReportQuery({
    vettingReportId,
    brandId,
  });

  if (!vettingReport) {
    return <div>Loading.....</div>;
  }

  return (
    <Page
      id="vetting-report"
      className={styles.vettingReportContainer}
      childrenClassName={styles.vettingReportWrapper}
    >
      <CreatorVettingReport vettingReport={vettingReport} showProfileInfo />
    </Page>
  );
}

export default VettingReport;
